
@font-face {
  font-family: Ubuntuth;
  src: url(/public/css/fonts/Ubuntu-Th.ttf);
}
@font-face {
  font-family: Ubuntureg;
  src: url(/public/css/fonts/Ubuntu-Regular.ttf);
}
@font-face {
  font-family: Ubuntumed;
  src: url(/public/css/fonts/Ubuntu-Medium.ttf);
}
@font-face {
  font-family: Ubuntulig;
  src: url(/public/css/fonts/Ubuntu-Light.ttf);
}
@font-face {
  font-family: Ubuntubol;
  src: url(/public/css/fonts/Ubuntu-Bold.ttf);
}
@font-face {
  font-family: Ubuntuligitl;
  src: url(/public/css/fonts/Ubuntu-LightItalic.ttf);
}
section {
  scroll-margin-top: 90px;
}
h2, h3{font-family: 'Ubuntuth', sans-serif !important;}
p{font-family: 'Ubuntureg', sans-serif !important; font-size: 18px;}
/* Global Colors */
:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #212529;
  --default-color-rgb: 33, 37, 41;
  --primary-color: #e84545;
  --primary-color-rgb: 232, 69, 69;
  --secondary-color: #32353a;
  --secondary-color-rgb: 50, 53, 58;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
}

/* Nav Menu Colors */
:root {
  --nav-color: #3a3939;
  --nav-hover-color: #e84545;
  --nav-dropdown-color: #3a3939;
  --nav-dropdown-hover-color: #e84545;
  --nav-dropdown-background-color: #ffffff;
  --nav-mobile-background-color: #ffffff;
}
/* Smooth scroll */
:root {
scroll-behavior: smooth;
}


::-webkit-scrollbar{
width: 6px;
}
::-webkit-scrollbar-track{
background-color: #000;
}
::-webkit-scrollbar-thumb{
background-color: #d83535;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
color: var(--default-color);
background-color: var(--background-color);
}

a {
color: var(--primary-color);
text-decoration: none;
transition: 0.3s;
}

a:hover {
color: rgba(var(--primary-color-rgb), 0.7);
text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
color: var(--secondary-color);
font-family: var(--heading-font);
}

section {
color: var(--default-color);
background-color: var(--background-color);
padding: 60px 0;
overflow: clip;
}

/*--------------------------------------------------------------
# Section Title
--------------------------------------------------------------*/
.section-title {
text-align: center;
padding-bottom: 60px;
}

.section-title h2 {
font-size: 32px;
font-weight: 700;
position: relative;
}

.section-title h2:before,
.section-title h2:after {
content: "";
width: 50px;
height: 2px;
background: var(--primary-color);
display: inline-block;
}

.section-title h2:before {
margin: 0 15px 10px 0;
}

.section-title h2:after {
margin: 0 0 10px 15px;
}

.section-title p {
margin-bottom: 0;
}

/*--------------------------------------------------------------
# Page Title & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
color: var(--default-color);
background-color: var(--background-color);
}

.page-title .heading {
padding: 80px 0;
border-top: 1px solid rgba(var(--default-color-rgb), 0.1);
}

.page-title .heading h1 {
font-size: 38px;
font-weight: 700;
color: var(--secondary-color);
}

.page-title nav {
background-color: rgba(var(--default-color-rgb), 0.05);
padding: 20px 0;
}

.page-title nav ol {
display: flex;
flex-wrap: wrap;
list-style: none;
margin: 0;
font-size: 16px;
font-weight: 600;
}

.page-title nav ol li+li {
padding-left: 10px;
}

.page-title nav ol li+li::before {
content: "/";
display: inline-block;
padding-right: 10px;
color: rgba(var(--default-color-rgb), 0.3);
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.arihantfixedtop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.header {
color: var(--default-color);
background-color: var(--background-color);
padding: 25px 0;
transition: all .5s ease-in-out;
z-index: 997;
border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.header .logo.scrolled img {
max-height: 40px;
margin-right: 6px;
filter: brightness(grayscale);
}

.header .logo h1 {
font-size: 24px;
margin: 0;
font-weight: 600;
color: var(--secondary-color);
}

.header .logo span {
color: var(--primary-color);
font-size: 24px;
font-weight: 600;
padding-left: 3px;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
color: var(--contrast-color);
background: var(--primary-color);
font-size: 14px;
padding: 8px 26px;
margin: 0;
border-radius: 4px;
transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
color: var(--contrast-color);
background: rgba(var(--primary-color-rgb), 0.85);
}

@media (max-width: 1200px) {
.header .logo {
  order: 1;
}

.header .btn-getstarted {
  order: 2;
  margin: 0 15px 0 0;
  padding: 6px 20px;
}

.header .navmenu {
  order: 3;
}
}

/* Global Header on page scroll
------------------------------*/
.scrolled .header {
--background-color: #ffffff;
--secondary-color: #444444;
--nav-color: #444444;
--nav-hover-color: #e84545;
box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
}

/* Global Scroll Margin Top
------------------------------*/
/* section {
scroll-margin-top: 90px;
} */

@media (max-width: 1199px) {
section {
  scroll-margin-top: 66px;
}
}

/* Home Page Custom Header
------------------------------*/
.index-page .header {
--background-color: rgba(255, 255, 255, 0);
--secondary-color: #ffffff;
--nav-color: rgba(255, 255, 255, 0.515);
--nav-hover-color: #ffffff;
}

/* Home Page Custom Header on page scroll
------------------------------*/
.index-page.scrolled .header {
--background-color: #000;
--secondary-color: #444444;
--nav-color: #444444;
--nav-hover-color: #d83535;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
@media (min-width: 1200px) {
.navmenu {
  padding: 0;
}

.navmenu ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navmenu li {
  position: relative;
}

.navmenu a,
.navmenu a:focus {
  color: #fff;
  padding: 1px 15px;
  font-size: 16px;
  font-family: var(--nav-font);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
  line-height: 16px;
}



.navmenu a i,
.navmenu a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
  transition: 0.3s;
}

.navmenu li:last-child a {
  padding-right: 0;
}

.navmenu li:hover>a,
.navmenu .active,
.navmenu .active:focus {
  color: #F15922;
  /* font-weight: bold; */
}

.navmenu .dropdown ul {
  margin: 0;
  padding: 10px 0;
  background: var(--nav-dropdown-background-color);
  display: block;
  position: absolute;
  visibility: hidden;
  left: 14px;
  top: 130%;
  opacity: 0;
  transition: 0.3s;
  border-radius: 4px;
  z-index: 99;
}

.navmenu .dropdown ul li {
  min-width: 200px;
}

.navmenu .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  color: var(--nav-dropdown-color);
}

.navmenu .dropdown ul a i {
  font-size: 12px;
}

.navmenu .dropdown ul a:hover,
.navmenu .dropdown ul .active:hover,
.navmenu .dropdown ul li:hover>a {
  color: var(--nav-dropdown-hover-color);
}

.navmenu .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navmenu .dropdown .dropdown ul {
  top: 0;
  left: -90%;
  visibility: hidden;
}

.navmenu .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: -100%;
  visibility: visible;
}

.navmenu .megamenu {
  position: static;
}

.navmenu .megamenu ul {
  margin: 0;
  padding: 10px;
  background: var(--nav-dropdown-background-color);
  box-shadow: 0px 0px 20px rgba(var(--default-color-rgb), 0.1);
  display: block;
  position: absolute;
  top: 130%;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: 0.3s;
  border-radius: 4px;
  z-index: 99;
}

.navmenu .megamenu ul li {
  flex: 1;
}

.navmenu .megamenu ul li a,
.navmenu .megamenu ul li:hover>a {
  padding: 10px 20px;
  font-size: 15px;
  color: var(--nav-dropdown-color);
}

.navmenu .megamenu ul li a:hover,
.navmenu .megamenu ul li .active,
.navmenu .megamenu ul li .active:hover {
  color: var(--nav-dropdown-hover-color);
}

.navmenu .megamenu:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navmenu .dd-box-shadow {
  box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.15);
}
}

/* Mobile Navigation */
@media (max-width: 1199px) {
.mobile-nav-toggle {
  color: var(--nav-color);
  font-size: 28px;
  line-height: 0;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s;
  width: 30px;
}

.navmenu {
  padding: 0;
  z-index: 9997;
}

.navmenu ul {
  display: none;
  position: absolute;
  inset: 60px 20px 20px 20px;
  padding: 10px 0;
  margin: 0;
  border-radius: 6px;
  background-color: var(--nav-mobile-background-color);
  overflow-y: auto;
  transition: 0.3s;
  z-index: 9998;
  box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.1);
}

.navmenu a,
.navmenu a:focus {
  color: var(--nav-dropdown-color);
  padding: 10px 20px;
  font-family: var(--nav-font);
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
}

.navmenu a i,
.navmenu a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  background-color: rgba(var(--primary-color-rgb), 0.1);
}

.navmenu a i:hover,
.navmenu a:focus i:hover {
  background-color: var(--primary-color);
  color: var(--contrast-color);
}

.navmenu a:hover,
.navmenu .active,
.navmenu .active:focus {
  color: var(--nav-dropdown-hover-color);
}

.navmenu .active i,
.navmenu .active:focus i {
  background-color: var(--primary-color);
  color: var(--contrast-color);
  transform: rotate(180deg);
}

.navmenu .dropdown ul,
.navmenu .megamenu ul {
  position: static;
  display: none;
  z-index: 99;
  padding: 10px 0;
  margin: 10px 20px;
  background-color: var(--nav-dropdown-background-color);
  transition: all 0.5s ease-in-out;
}

.navmenu .dropdown ul ul,
.navmenu .megamenu ul ul {
  background-color: rgba(33, 37, 41, 0.1);
}

.navmenu .dropdown>.dropdown-active,
.navmenu .megamenu>.dropdown-active {
  display: block;
  background-color: rgba(33, 37, 41, 0.03);
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav-toggle {
  color: #fff;
  position: absolute;
  font-size: 32px;
  top: 15px;
  right: 15px;
  margin-right: 0;
  z-index: 9999;
}

.mobile-nav-active .navmenu {
  position: fixed;
  overflow: hidden;
  inset: 0;
  background: rgba(33, 37, 41, 0.8);
  transition: 0.3s;
}

.mobile-nav-active .navmenu>ul {
  display: block;
  height: 30vh;
}
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
position: fixed;
visibility: hidden;
opacity: 0;
right: 15px;
bottom: 15px;
z-index: 99999;
background-color: var(--primary-color);
width: 40px;
height: 40px;
border-radius: 4px;
transition: all 0.4s;
}

.scroll-top i {
font-size: 24px;
color: #ffffff;
line-height: 0;
}

.scroll-top:hover {
background-color: rgba(var(--primary-color-rgb), 0.8);
color: #ffffff;
}

.scroll-top.active {
visibility: visible;
opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
position: fixed;
inset: 0;
z-index: 9999;
overflow: hidden;
background-color: var(--background-color);
transition: all 0.6s ease-out;
width: 100%;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
}

#preloader div {
width: 13px;
height: 13px;
background-color: var(--primary-color);
border-radius: 50%;
animation-timing-function: cubic-bezier(0, 1, 1, 0);
position: absolute;
left: 50%;
}

#preloader div:nth-child(1) {
left: calc(50% + 8px);
animation: animate-preloader-1 0.6s infinite;
}

#preloader div:nth-child(2) {
left: calc(50% + 8px);
animation: animate-preloader-2 0.6s infinite;
}

#preloader div:nth-child(3) {
left: calc(50% + 32px);
animation: animate-preloader-2 0.6s infinite;
}

#preloader div:nth-child(4) {
left: calc(50% + 56px);
animation: animate-preloader-3 0.6s infinite;
}

@keyframes animate-preloader-1 {
0% {
  transform: scale(0);
}

100% {
  transform: scale(1);
}
}

@keyframes animate-preloader-3 {
0% {
  transform: scale(1);
}

100% {
  transform: scale(0);
}
}

@keyframes animate-preloader-2 {
0% {
  transform: translate(0, 0);
}

100% {
  transform: translate(24px, 0);
}
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
[data-aos-delay] {
  transition-delay: 0 !important;
}
}

/*--------------------------------------------------------------
# Home Page
--------------------------------------------------------------*/
/* Hero Section - Home Page
------------------------------*/
.hero {
--default-color: #ffffff;
/* --default-color-rgb: 255, 255, 255;
--background-color: #000000;
--background-color-rgb: 0, 0, 0; */
width: 100%;
height: 730px;
position: relative;
padding: 135px 0 100px 0;
/* display: flex;
align-items: center;
justify-content: center; */
}

.hero img {
position: absolute;
inset: 0;
display: block;
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
z-index: 1;
}

/* .hero:before {
content: "";
background: rgba(var(--background-color-rgb), 0.5);
position: absolute;
inset: 0;
z-index: 2;
} */

.hero .container {
position: relative;
z-index: 3;
}

/* .hero h2 {
color: var(--contrast-color);
margin: 0;
font-size: 44px;
font-weight: 700;
}

.hero p {
color: rgba(var(--default-color-rgb), 0.8);
margin: 5px 0 0 0;
font-size: 20px; 
}
*/
/* .hero .sign-up-form {
margin-top: 20px;
padding: 10px;
border-radius: 7px;
background: #fff;
box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
}

.hero .sign-up-form .form-control {
border: none;
}

.hero .sign-up-form .form-control:active,
.hero .sign-up-form .form-control:focus {
outline: none;
box-shadow: none;
} */

/* .hero .sign-up-form input[type=submit] {
box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
background-color: var(--primary-color);
border-color: var(--primary-color);
padding: 8px 20px 10px 20px;
border-radius: 7px;
color: var(--contrast-color);
}

.hero .sign-up-form input[type=submit]:hover {
background-color: rgba(var(--primary-color-rgb), 0.9);
} */

@media (max-width: 768px) {
.hero h2 {
  font-size: 32px;
}

.hero p {
  font-size: 18px;
}
}


@keyframes animate-loading {
0% {
  transform: rotate(0deg);
}

100% {
  transform: rotate(360deg);
}
}


.logo img{width: 130px;}

.formdiv{position: absolute; top: 0; right: 0; z-index: 999; background-color: rgba(0, 0, 0, 0.8); padding:40px 20px; width: 35%;}
.formdiv .form-group{margin-bottom: 20px; position: relative;}
.formdiv .form-control{background-color: transparent; border: none; border-bottom: 1px solid #fff; border-radius: 0px;
  margin-bottom: 5px; height: 60px; color: #fff;}
  .formdiv label{font-size: 16px; color: #fff; opacity: 0.5; font-weight: 100;}
  .formdiv .form-control:hover, .formdiv .form-control:focus{border-bottom: 1px solid #d83535; color: #fff; box-shadow: none;}
.formdiv textarea.form-control{height: 100px;}
.formdiv h2{font-weight: 300; color: #fff; text-align: center;}
.Aubussiness-main {width: 100%; background-color: #000; padding: 50px 0px;}
.aboutmain{width: 100%; background-image: url("/public/images/about-bg.png"); background-size: 100% 100%; padding-top: 150px; padding-bottom: 150px;}
.aboutmain h5{color: #808285; font-size: 22px; font-weight: 400;}
.aboutmain h5 span{color: #808285; font-size: 22px; font-weight: 500; font-style: italic;}
.aboutmain p{color: #808285; font-size: 18px; margin-bottom: 25px;  margin-top: 30px;
font-family: 'Ubuntuligitl', sans-serif !important;}
.aboutmain h3{color: #F15922; font-size: 48px; font-family: 'Ubuntubol', sans-serif !important;}
.aboutmain h2{color: #003189; font-size: 40px; margin-bottom: 40px; font-weight: 500; font-family: 'Ubuntumed', sans-serif !important;}
.aboutmain .areaImagesSwiper .swiper-wrapper {margin-bottom: 1.5rem;}
.aboutmain .areaImagesSwiper .swiper-scrollbar {height: 1px; left: 0; width: 100%;}
.aboutmain .areaImagesSwiper .swiper-scrollbar .swiper-scrollbar-drag {background: #000; height: 2px;}
.lifeStyleSwiper .swiper-wrapper {
  margin-bottom: 1rem;
}
.lifeStyleSwiper .swiper-wrapper .swiper-slide {width: 100%; aspect-ratio: 51.6 / 33.9;}
.lifeStyleSwiper .swiper-wrapper .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.lifeStyleSwiper .swiper-scrollbar {height: 1px !important; left: 0 !important; width: 100% !important; background: #fff;}
.lifeStyleSwiper .swiper-scrollbar .swiper-scrollbar-drag {background: #000; height: 1px;}
#ownslider{margin-top: 50px;}
.carddiv p{margin-top: 0px; font-family: 'Ubuntureg', sans-serif !important; color: #000;}

.lifestylemain{width: 100%; background:#003189 url('/public/images/blue-pattern.png') no-repeat; opacity: 1; padding: 100px 0px;
background-size: 100% 100%;}

.lifestylemain h2{font-size: 60px; color: #fff;}
.lifestylemain p{font-size: 18px; color: #fff; line-height: 30px; margin-top: 30px; padding-right: 110px;}
.lifestylemain span{float: right; font-size: 10px; color: #fff; font-family: 'Ubuntuligitl', sans-serif; margin-top: 10px;}

.Buildyourvillamain{width: 100%; padding: 150px 0px 100px 0px; display: flex; align-items: center; justify-content: center;}
.Buildyourvillamain img{width: 100%; }
.Buildyourvillamain span{font-style: italic; font-size: 10px; color: #808285;}
.Buildyourvillamain h2{font-size: 60px; color: #000; padding-right: 150px; font-weight: 100; margin-bottom: 40px;}
.Buildyourvillamain p{color: #808285; font-size: 18px; margin-bottom: 30px; padding-right: 100px;}
.Buildyourvillamain a{padding: 13px 50px; border: 1px solid #F15922; color: #F15922;float: left; font-weight: 500; 
font-size: 18px; transition: all .5s ease-in-out;}
.Buildyourvillaright{padding-left: 100px;}

.Buildyourvillamain a:hover{background-color: #F15922; color: #fff;}

.layoutplanmain{width: 100%; padding: 100px 0px; background-color: #F9F9F9;}
.layoutplanmain h2{font-size: 60px; font-weight: 100; margin-bottom: 40px;}
.layoutplanmain img{width: 100%; margin-bottom: 50px;}
.layoutplanmain h3{font-size: 60px; font-weight: 100;}
.layoutplanmain ul{margin: 0px; padding: 0px;}
.layoutplanmain ul li{width: 33%; float: left; list-style-type: auto; margin-bottom: 20px; color: #F15922; font-size: 16px; font-family: 'gilroymd', sans-serif;}
.layoutplanmain ul li span{color: #808285;}

.whychooseusmain{width: 100%; padding: 0px 0px 100px 0px; }
.whychooseusmain h2{font-size: 60px; font-weight: 100; margin-bottom: 40px;}
.whychooseusmain p{color: #808285; font-size: 18px; padding-right: 50px;}
.whychooseusmain span{color: #808285; font-size: 18px; font-family: 'Ubuntuligitl', sans-serif !important;}
.whychooseusright{padding-right: 50px;}

.Aubussinessmain{width: 100%; padding: 100px 0px; background-color: #F9F9F9; text-align: center;}
.Aubussinessmain h3{font-size: 60px; color: #000; text-align: center; font-weight: 100; margin-bottom: 50px;}
.Aubussinessmain img{width: 120px; transition: all 0.5s ease-in-out;}
.Aubussinessmain img:hover{opacity: 10;}
.Aubussinesslogo{width: 70%; display: flex; align-items: center; justify-content: space-between;}

.homeloadmain{width: 100%; padding: 50px 0px 100px 0px}
.homeloadmain h3{font-size: 50px; font-weight: 100; color: #fff;}
.homeloanlogodiv {width: 100%; background-color: #003189; padding: 50px; border-radius: 24px;}

.slideronemain{width: 100%; padding: 50px 0px;}
.carddiv{width: 100%; padding: 0px 0px;}
.carddiv img{width: 100%; margin-bottom: 20px;}

.owl-stage-outer {overflow: hidden}  
.offers {position: relative}  
.offers .owl-item {float: left;}  
/* .offers .owl-nav {position: absolute; bottom: 50%; width: 100%; display: none;}  
.offers .owl-next {position: absolute; right: 0px; width: 35px; height: 35px; line-height: 20px; text-align: center; padding: 8px 14px;
  color: #767676; background-color: transparent; cursor: pointer; border-radius: 50%; font-size: 20px; vertical-align: middle;}  
.offers .owl-prev {position: absolute; left: 0px; width: 35px; height: 35px; line-height: 20px; cursor: pointer; text-align: center;
  background-color: transparent; padding: 8px 10px; color: #f00; border-radius: 50%; font-size: 20px; vertical-align: middle;}
.offers .owl-prev .fal { font-size: 22px;}  
.offers .owl-next .fal { font-size: 22px;}   */
.owl-dots {text-align: left; margin-top: 0px; padding-right: 0px; margin-left: 0px; width: 100%;}  
.owl-dots .owl-dot {display: inline-block; cursor: pointer; width: 33.3%;}  
.owl-dots .owl-dot span {width: 100%; height: 1px; margin: 5px 4px; background: #ddd; display: inline-block;}  
.owl-dots .owl-dot.active span {background: #000; opacity: 1; width: 100%; height: 1.5px; box-shadow: none !important;}
.banklogodiv{width: 31%; background-color: #fff; padding: 15px; text-align:center; height: 150px; display: flex;
align-items: center; justify-content: center; border-radius: 12px;}
.banklogodiv img{width: 100%;}
.banklogomain{display: flex; align-items: center; justify-content: space-between;}

.aboutmain .owl-dots .owl-dot{width: 50%;}
.lifestylemain .owl-dots .owl-dot{width: 25%;}

footer{width: 100%; padding: 50px 0px 25px 0px; background-color: #F9F9F9;}
.footer-logo{width: 70%;}
.footer-logo ul{margin: 0px; padding: 0px; display: flex; align-items: center; justify-content: space-between;}
.footer-logo ul li{display: inline-block; width: 50%; text-align: center;}
.footer-logo ul li:first-child{border-right: 1px solid rgba(0, 0, 0, 0.3); text-align: left;}
footer h5{color: #000; font-family: 'Ubuntumed', sans-serif !important; }
footer h6{color: #000; font-family: 'Ubuntumed', sans-serif !important;  margin-top: 40px; font-size: 1.25rem;}
footer p{color: #000; padding-right: 50px; font-size: 16px; font-family: 'Ubuntulig', sans-serif !important; opacity: 0.7;}
.footer-logo img{width: 100px;}
.reradiv p{font-family: 'Ubuntureg', sans-serif; color: #000; opacity: 10; font-size: 16px;}

.gallerymain{width: 100%; padding: 50px 0px 100px 0px;}
.gallerymain h2{font-size: 60px; margin-bottom: 40px; font-weight: 100;}

.gallerymain .GallerySwiper .swiper-wrapper {margin-bottom: 1.5rem;}
.gallerymain .GallerySwiper .swiper-scrollbar {height: 1px;}
.gallerymain .GallerySwiper .swiper-scrollbar .swiper-scrollbar-drag {background: #000; height: 2px;}

.constructionupdatesmain{width: 100%; padding: 50px 0px 50px 0px;}

.constructionupdatesmain .constructionSwiper .swiper-wrapper {margin-bottom: 1.5rem;}
.constructionupdatesmain .constructionSwiper .swiper-scrollbar {height: 1px;}
.constructionupdatesmain .constructionSwiper .swiper-scrollbar .swiper-scrollbar-drag {background: #000; height: 2px;}

.enclaveVideo {
  width: 100%;
  height: auto;
}

.enclaveVideo div {
  width: 100%;
  aspect-ratio: 16 / 5;
}

.enclaveVideo div iframe {
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
.location{width: 100%; position: relative; padding: 0px 0px 100px 0px;}

.locationdiv{width: 100%; position: relative;}
.locationcircle {width: 750px; height: 500px;
text-align: center; padding-top: 130px; position: relative; background-image: url("/public/images/location-icon.png"); background-size: 100% 100%;}
.locationcircle h3{font-size: 60px;}

.lectionleft {margin-left: 1%; position: absolute; left: 0; width: 23%; margin-top: 5%;}
.lectionleft ul{margin: 0px; padding: 0px;}
.lectionleft ul li{list-style-type: none; text-align: right; margin-bottom: 66px; font-weight: 600; font-size: 14px;}
.lectionleft ul li:first-child{margin-left: 6%;}

.lectionright {margin-right: 1%; position: absolute; right: 0; top: 0; width: 23%; margin-top: 5%;}
.lectionright ul{margin: 0px; padding: 0px;}
.lectionright ul li{list-style-type: none; text-align: left; margin-bottom: 66px; font-weight: 600; font-size: 14px;}
.lectionright ul li:first-child{margin-left: 6%;}
.mr-50{margin-right: 60px;}
.ml-50{margin-left: 60px;}
.ml-10{margin-left: 20px;}
.location a{color: #F15922; font-size: 18px; text-align: right; float: right;}

.form-check {margin-bottom: 40px; margin-top: 10px;}
.form-check label{font-size: 12px; color: #fff; opacity: 0.5;}
.form-check-input{background-color: transparent; border: 1px solid #fff; border-radius: 0px;}
.form-check-input[type=checkbox]{border-radius: 0px;}

.navmenu li span{border-left: 1px solid #fff; float: left;}
.navmenu li span a{color: #fff; text-decoration: underline;}
.btnmain{padding: 10px 50px; font-size: 18px; color: #fff; background-color: #F15922; border: none; border: 1px solid #F15922;
  float: left; font-weight: 500; transition: all .5s ease-in-out;}
  .btnmain:hover{background-color: transparent; color: #fff; border: 1px solid #F15922;}
/* .index-page.scrolled .logo img{filter: brightness(0) invert(0);} */
/* .index-page.scrolled .navmenu li span a{color: #F15922;}
.index-page.scrolled .navmenu li span {border-left: 1px solid #F15922; float: left;} */

.contentdetail ul{margin: 0px; padding: 0px;}
.contentdetail ul li{list-style-type: none; margin-bottom: 15px;}
.contentdetail ul li a{color: #000; font-size: 16px;}
.contentdetail ul li img{margin-right: 10px; width: 20px; filter: brightness(0);}

.social-logo h5{margin-top: 40px;}
.social-logo ul{margin: 0px; padding: 0px; display: flex; align-items: center;}
.social-logo ul li{list-style-type: none; margin-bottom: 15px;}
.social-logo ul li a{color: #000; font-size: 16px;}
.social-logo ul li img{margin-right: 15px; filter: brightness(0); width: 35px;}

.footer-bottom{width: 100%; border-top: 1px solid rgba(0, 0, 0, 5); margin-top: 30px; padding-top: 20px;}
.footer-bottom span{color: #000; font-size: 12px;}

.constructionupdatesdiv {display: flex; align-items: flex-start; justify-content: space-between;}
.constructionupdatesdiv h2{font-size: 60px; margin-bottom: 60px;}
.constructionupdatesdiv .form-control{width: 12%; background-color: #003189; color: #fff; border-radius: 0px;
  appearance: auto; margin-top: 20px; border: none; padding: 10px;}
  

.monthyearpicker{background-color: #003189; margin-top: 20px; padding: 10px; border: none; color: #fff}
.gallerytext{float: right; font-size: 10px; margin-top: 10px;}

.form-errors{color: #f00; position: absolute; font-size: 14px !important; font-weight: normal; bottom: -40px;}
.form-check .form-errors{left: 20px; bottom: auto;}


::-webkit-calendar-picker-indicator{
  background-color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.thanyoumain{padding: 150px 0px; text-align: center;}
.thanyoumain img{margin-bottom: 30px; }
.thanyoumain h1{font-size: 90px; margin-bottom: 20px; text-transform: uppercase; font-family: 'Ubuntubol', sans-serif;}
.thanyoumain p{font-size: 20px; margin-bottom: 30px;}
.thankyouimg{height: 300px;}
.thanyoumainwrapper .hero {
  --default-color: #ffffff;
  width: 100%;
  min-height: 400px;
  position: relative;
  padding: 0px 0 70px 0;
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: #003189;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #ffffff;
  line-height: 0;
}

.scroll-top:hover {
  background-color: rgba(0, 49, 137, 0.8);
  color: #ffffff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

.constructionupdatesmain span{float: right; font-style: italic;
  font-size: 10px;
  color: #808285;
}
.modalone h2{font-weight: 700; color: #000; text-transform: uppercase; font-family: 'Ubuntubol', sans-serif !important;
text-align: center; margin-bottom: 50px;}
.modalone p{font-size: 14px; line-height: 24px; font-family: 'Ubuntureg', sans-serif !important; color: #000;}
.modalone .btnmain{float: none;}
/* .modal-header{border-bottom: none;}
.modal-body{padding-bottom: 50px; margin-top: 30px;}
.modal .form-check .form-check-label{font-size: 14px; font-family: 'Ubuntubol', sans-serif !important;} */

.ant-modal{width: 900px !important; top: 50px;}
.modalone .btnmain:hover{color: #F15922;}

.commingsoon {
  background-image: url(/public/images/background-2-1.jpg);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
}
.content {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  padding: 2rem 0;
}
.content h6 {
  font-size: 21px;
  line-height: 32px;
  max-width: 684px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.content h6 strong {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
}
.content p {
  line-height: 1.6;
  word-spacing: 0px;
  color: black;

  margin-bottom: 20px;
  font-size: 22px;
  letter-spacing: 0.02em;
}
.content p a {
  text-decoration: none;
  color: #000;
}
.logonew {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.logonew img {
  position: relative;
  max-width: 210px;
}
.logo,
.content {
  display: block;
}
.content h1 {
  font-family: "Playfair Display", serif;
  font-size: 62px;
  color: #c9242d;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 600px) {
  .logonew img {
    width: 100%;
  }
  .content h1 {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    color: #c9242d;
    letter-spacing: 0px;
  }
  .content {
    padding: 2rem 20px;
  }
  .content h6 {
    font-size: 19px;
  }
  .content p {
    font-size: 20px;
    word-spacing: 0px;
  }
}
.aboutmain .owl-dots{display: block !important;}
.lifestylemain .owl-dots{display: block !important;}
.constructionupdatesmain .owl-dots{display: block !important;}
.gallerymain .owl-dots{display: block !important;}