@media only screen and (max-width:1366px) {
    .header-main .navbar-expand-lg .navbar-nav {
        justify-content: flex-start;
        margin-left: 5%
    }

    .aurealestate-bussinesscontentdiv p {
        color: #fff;
        width: 70%
    }

    .aurealestate-bussinesscontentdivbottom p {
        color: #fff;
        width: 95%;
        min-height: 80px
    }

    .line2 {
        height: 89%;
        border-right: 1px solid #0f817f;
        left: 86.8%
    }

    .line3 {
        width: 86.8%
    }

    .line4 {
        right: 4.9%
    }

    .line5 {
        width: 95%;
        bottom: 69px
    }

    .accordion-body p {
        font-size: 16px
    }

    .accordion-button {
        color: #a4a4a4;
        font-size: 20px
    }

    .ourprojectstop nav {
        width: 45%
    }

    .productlist-tab .nav-tabs .nav-link {
        font-size: 25px
    }
}

@media only screen and (max-width:1024px) {

    .aurealestate-innerbanner img,
    .themeplay,
    .themeplayone {
        object-fit: cover
    }

    h1 {
        font-size: 0px
    }

    h2,
    h3 {
        font-size: 40px !important
    }

    .header-main .navbar-brand img {
        width: 90px
    }

    .plusminus {
        width: 15%;
        text-align: right
    }

    .header-main .navbar-expand-lg .navbar-nav {
        justify-content: flex-end;
        margin-left: 6%
    }

    .header-main .navbar-expand-lg .navbar-nav .nav-link {
        padding: 0 10px
    }

    .aurealestate-content {
        width: 80%;
        bottom: 40%
    }

    .findusheretopdiv {
        padding: 50px 0
    }

    .auvalues-main img,
    .foundermessage-rightdiv img,
    .leadershipteam-main p {
        width: 100%
    }

    .accordion-button {
        color: #a4a4a4;
        font-size: 20px;
        padding: 10px
    }

    .accordion-body p {
        font-size: 14px
    }

    .aurealestate-bussinesscontentdiv p,
    .findusherediv-left p {
        width: 90%
    }

    .aurealestate-bussinesscontentdivbottom {
        bottom: -95px
    }

    .aurealestate-bussinesscontentdivbottom p {
        min-height: 70px
    }

    .newsslider {
        margin-left: 2%
    }

    .testimonial-main {
        grid-template-columns: 45% 55%;
    }

    .testimonial-main .testimonial-right {
        width: 100%;
    }

    .testimonial-main .testimonial-right::before {
        top: -0%;
        left: 5%;
    }

    .testimonial-main .testimonial-right .swiper-slide {
        padding: 0rem 1rem 1rem 3rem;
    }

    .testimonial-main .testimonial-right .swiper-slide p {
        margin-top: 5rem;
    }

    .testimonial-main .testimonial-right .testimonials-swiper-button {
        justify-content: flex-start;
        margin: 0 0 0 3rem;
    }

    .alllogodiv img {
        width: 90px;
        filter: brightness(1) invert(1)
    }

    .aboutuspage h2::after,
    .aboutuspage h2::before,
    .aboutuspageline,
    .advisoryline,
    .aurealestate-auphilosopy h2::after,
    .aurealestate-auphilosopy h2::before,
    .aurealestate-auphilosopyline,
    .aurealestate-bussiness h2::before,
    .auvalues-auvaluesline,
    .auvalues-main h3::after,
    .auvalues-main h3::before,
    .awards-leftdiv h2::before,
    .consultingpartners-main h2::before,
    .exceeding-expectations h2::before,
    .findusherediv h2::after,
    .findusherediv h2::before,
    .findushereline,
    .leadershipteam-main h2::before,
    .newsmain h2::before,
    .ourprojectstop h2::before,
    .service-div h2::before,
    .serviceline {
        display: none
    }

    .findusherediv-left,
    .leasingdiv-right {
        width: 40%
    }

    .aurealestate-innerbanner {
        height: 400px
    }

    .findusherediv-left h4 {
        font-size: 26px
    }

    .missiondiv p,
    .productlist-tab .nav-tabs .nav-link,
    .visiondiv p {
        font-size: 20px
    }

    .mapmap {
        width: 100%;
        height: 350px
    }

    .visiondiv p {
        min-height: 150px !important
    }

    .aboutuspage p {
        width: 100%;
        min-height: auto
    }

    .newsmediadivright h3 {
        font-size: 28px !important;
        margin-bottom: 20px
    }

    .newsmediadivleft {
        width: 40%;
        margin-right: 2%
    }

    .newsmediadivright,
    .ourprojectstop nav {
        width: 50%
    }

    .faq-leftdiv {
        width: 33%
    }

    .service-div p {
        width: 100%;
        margin-bottom: 30px
    }

    .leasingdiv-left {
        width: 55%
    }

    .breadcrumdiv {
        margin-bottom: 20px
    }

    .leadership-contentdiv {
        bottom: -65%
    }

    .aboutuspage {
        background-repeat: no-repeat;
        padding: 0 0 50px
    }

    .awards-leftdiv {
        padding-left: 20px
    }

    .leadershipteam-main {
        padding-top: 50px
    }

    .awards-main .owl-carousel .owl-nav {
        position: absolute;
        width: 30%;
        left: -45%;
        top: 80% !important
    }

    .foundermessage-line {
        width: 100%;
        position: static;
        display: none
    }

    .themeplayone {
        width: 100% !important;
        height: 550px !important
    }

    .ytvideo {
        height: 460px
    }

    .youtubevideo {
        width: 100%;
        height: 460px
    }

    .themeplay {
        width: 100% !important;
        height: 580px !important
    }

    .aurealestate-auphilosopy {
        max-width: 100vw
    }

    .aurealestate-auphilosopy p {
        width: 100%;
        padding-left: 0;
        margin-bottom: 50px
    }

    .exceeding-expectations h2 {
        padding-left: 40px
    }

    .jointventures img {
        width: 100%;
        margin-left: 0
    }

    .ourprojectscontentdiv {
        width: 50%;
        top: 15%
    }

    .aboutuspage h2,
    .aurealestate-auphilosopy h2,
    .auvalues-main h3 {
        width: 100%;
        background-color: transparent
    }
}

@media only screen and (max-width:820px) {

    .alllogodiv,
    .foundermessage-div {
        margin-bottom: 30px
    }

    h1 {
        font-size: 0px
    }

    h2,
    h3 {
        font-size: 30px !important
    }

    .aurealestate-auphilosopyline,
    .aurealestate-bussiness h2::before {
        display: none
    }

    .aurealestate-auphilosopy p {
        width: 100%;
        padding-left: 0
    }

    .testimonial-main {
        grid-template-columns: 45% 55%;
    }

    .testimonial-main .testimonial-right {
        width: 100%;
    }

    .testimonial-main .testimonial-right::before {
        top: -0%;
        left: 5%;
    }

    .testimonial-main .testimonial-right .swiper-slide {
        padding: 0rem 1rem 1rem 3rem;
    }

    .testimonial-main .testimonial-right .swiper-slide p {
        margin-top: 5rem;
    }

    .testimonial-main .testimonial-right .testimonials-swiper-button {
        justify-content: flex-start;
        margin: 0 0 0 3rem;
    }

    .alllogodiv {
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 10px
    }

    .alllogodiv img {
        width: 100px;
        filter: brightness(1) invert(1);
        margin-bottom: 20px
    }

    .foundermessage-main {
        padding-bottom: 0;
        padding-top: 0
    }

    .accordion-header {
        margin-bottom: 10px !important
    }

    .auvalues-main img,
    .visiondiv {
        width: 100%;
        margin-bottom: 20px
    }

    .header-main .navbar-toggler img {
        width: 35px;
        filter: brightness(1) invert(1)
    }

    .header-main .navbar-expand-lg .navbar-nav {
        justify-content: flex-start;
        margin-left: 0
    }

    .header-main .navbar-toggler:focus {
        box-shadow: none
    }

    .header-main .navbar-collapse {
        background-color: #fff;
        padding-top: 15px;
        padding-left: 0
    }

    .header-main li.nav-item {
        list-style-type: none !important;
        margin-bottom: 15px
    }

    .aurealestate-bussinesscontentdiv {
        padding: 0 15px;
        bottom: -70px
    }

    .aurealestate-bussinesscontentdiv img {
        width: 100px;
        margin-bottom: 20px
    }

    .aurealestate-bussinesscontentdiv p {
        font-size: 14px
    }

    .aurealestate-bussinesscontentdivbottom img {
        width: 100px
    }

    .aurealestate-bussinesscontentdivbottom {
        padding: 0 0 0 15px;
        bottom: -100px
    }

    .aurealestate-bussinesscontentdivbottom p {
        font-size: 14px;
        min-height: 85px
    }

    .exceeding-expectations-content {
        bottom: 20px
    }

    .testimonial-right p {
        font-size: 16px
    }

    .social-links ul li img {
        width: 25px
    }

    .awards-leftdiv,
    .footermainleft p {
        width: 90%
    }

    .awards-main,
    .leasingdiv,
    .ourprojectscontent,
    .visionandmissiondiv {
        display: inherit
    }

    .leasingdiv-left,
    .leasingdiv-right,
    .missiondiv {
        width: 100%
    }

    .newsmediadivright h3 {
        font-size: 24px !important;
        margin-bottom: 10px !important
    }

    .productlist-tab .nav-tabs .nav-link {
        width: 22%
    }

    .productlist-tab .nav-tabs .nav-link:last-child {
        width: 32%
    }

    .leadership-contentdiv {
        bottom: -70%
    }

    .awards-rightdiv {
        width: 90%;
        margin: 0 5%
    }

    .awards-main .owl-carousel .owl-nav {
        position: absolute;
        width: 70%;
        left: 30%;
        top: 110% !important;
        z-index: 999999
    }

    .themeplay {
        width: 100% !important;
        height: 432px !important;
        object-fit: cover
    }

    .ytvideo {
        height: 400px;
        background-size: 100% 100%
    }

    .youtubevideo {
        width: 100%;
        height: 400px
    }

    .wrapper {
        top: 60%
    }

    .newsmediadivright {
        width: 58%
    }

    .ourprojectstop nav {
        width: 65%
    }

    .ourprojectscontent img {
        width: 100% !important;
        margin-bottom: 20px
    }

    .ourprojectscontentdiv {
        width: 97%;
        top: 15%;
        position: inherit;
        float: left;
        margin: 20px
    }

    .btn-style img {
        width: 30px !important;
        margin-bottom: 0
    }

    .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-50%)
    }

    .navbar-nav .dropdown-menu {
        border: none
    }
}

@media (max-width:590px) {

    .aurealestate-banner {
        min-height: 100%;
        aspect-ratio: 34.1 / 20;
    }

    .alllogodiv,
    .career-div .btn-style,
    .footer-main-bottom {
        margin-top: 20px
    }

    h1 {
        font-size: 0px
    }

    h2,
    h3 {
        font-size: 24px !important
    }

    .newsmediadivright p,
    p {
        font-size: 16px
    }

    .header-main .navbar-toggler img {
        width: 35px;
        filter: brightness(1) invert(1)
    }

    .header-main .navbar-expand-lg .navbar-nav {
        justify-content: flex-start;
        margin-left: 0
    }

    .header-main .navbar-toggler:focus {
        box-shadow: none
    }

    .header-main .navbar-collapse {
        background-color: #fff;
        padding-top: 15px
    }

    .header-main li.nav-item {
        list-style-type: none !important;
        margin-bottom: 15px
    }

    .aurealestate-content {
        width: 90%;
        bottom: 30%;
        position: absolute;
        left: 10px
    }

    .aurealestate-content p {
        font-size: 16px;
        color: #000
    }

    .aurealestate-content h1 {
        font-size: 25px !important;
        color: #fff
    }

    .aurealestate-bussinessimgmainbottom,
    .aurealestate-bussinessimgmaintop,
    .faq-div,
    .findusherecontact,
    .foundermessage-div,
    .newsmediadiv,
    .ourprojectstop,
    .visionandmissiondiv {
        display: inherit
    }

    .aurealestate-bussinesscontentdiv p,
    .faq-leftdiv,
    .faq-rightdiv,
    .findusherediv-left p,
    .findusherediv-right,
    .foundermessage-leftdiv,
    .foundermessage-rightdiv,
    .foundermessage-rightdiv img,
    .missiondiv,
    .newsmediadivright,
    .testimonial-left {
        width: 100%
    }

    .alllogodiv {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 0
    }

    .alllogodiv img {
        margin-bottom: 30px;
        width: 100px
    }

    .aurealestate-auphilosopy p {
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px
    }

    .aurealestate-bussinessimgdiv,
    .aurealestate-bussinessimgdivbottom {
        width: 100%;
        margin-bottom: 10px
    }

    .auvalues-main img,
    .ourprojectstop nav,
    .visiondiv {
        width: 100%;
        margin-bottom: 20px
    }

    .accordion-header,
    .inthenewsdivtop h3,
    .otherdiv h1 {
        margin-bottom: 0 !important
    }

    .advisory-productlist-div,
    .productlisttab-div,
    .testimonial-left,
    .testimonial-rightone {
        margin-bottom: 30px
    }

    .testimonial-main .item {
        display: inherit;
        align-items: center
    }

    .testimonial-right {
        width: 100%;
        margin-left: 0;
        position: relative
    }

    .newsslider {
        margin-left: 0;
        padding: 0 15px
    }

    .header-main .navbar-brand img {
        width: 70px
    }

    .aurealestate-auphilosopyline,
    .aurealestate-bussiness h2::before,
    .findusherediv h2::after,
    .findusherediv h2::before,
    .findushereline,
    .foundermessage-line,
    .testimonial-right::before {
        display: none
    }

    .aurealestate-bussinesscontentdivbottom p {
        font-size: 14px;
        min-height: auto
    }

    .foundermessage-main {
        padding-top: 0
    }

    .testimonial-main {
        padding: 0 15px 100px;
        grid-template-columns: 1fr;
    }

    .testimonial-main .testimonial-right .swiper-slide{
        padding: 0;
    }

    .testimonial-main .testimonial-right .testimonials-swiper-button {
        margin: 0;
        justify-content: center;
    }

    .aurealestate-bussinesscontentdivbottom {
        padding: 0 15px;
        bottom: -70px
    }

    .accordion-button {
        color: #a4a4a4;
        font-size: 20px;
        padding: 10px 0
    }

    .accordion-body {
        padding: 0 0 15px
    }

    .testimonial-main .owl-carousel .owl-nav {
        position: absolute;
        top: 100% !important;
        width: 100%;
        right: 0;
        text-align: center
    }

    .auvalues-main,
    .consultingpartners-main {
        padding: 50px 0
    }

    .breadcrumdiv,
    .footermain ul {
        margin-bottom: 20px
    }

    .aurealestate-bussinesscontentdiv {
        padding: 0 15px;
        bottom: -57px
    }

    .exceeding-expectations-content {
        bottom: 0
    }

    .footermain {
        padding-bottom: 10px
    }

    .footer-main-bottom p {
        font-size: 16px;
        margin-bottom: 0
    }

    .auvalues-main h3 {
        padding-left: 15px
    }

    .aurealestate-innerbanner {
        height: 200px
    }

    .findusherediv-left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px
    }

    .findusherediv-left h4 {
        font-size: 26px
    }

    .aboutuspage p {
        width: 100%;
        font-size: 18px;
        min-height: auto
    }

    .newsmediadivleft {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px
    }

    .aurealestate-banner .themeplay {
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: 43.1 / 20;
        object-fit: cover;
        margin-bottom: 0
    }

    .youtubevideo,
    .ytvideo {
        width: 100%;
        height: 200px
    }

    .ytvideo {
        background-size: 100% 100%
    }

    .themeplayone {
        width: 100% !important;
        height: 200px !important;
        object-fit: cover
    }

    .productlist-tab,
    .service-div {
        padding: 0
    }

    .productlist-tab .nav-tabs .nav-link {
        width: 35%;
        font-size: 16px;
        margin-bottom: 10px
    }

    .productlist-tab .nav-tabs .nav-link:last-child {
        width: 50%
    }

    .advisory-productlist,
    .exceeding-expectations,
    .newsmain {
        padding-bottom: 0
    }

    .newsmediadivright h3 {
        font-size: 20px !important;
        margin-bottom: 10px !important
    }

    .leadershipteam-main {
        padding-bottom: 50px
    }

    .awards-main {
        padding-top: 50px
    }

    .consultingpartners-div {
        padding-left: 5%
    }

    .exceeding-expectations h2 {
        padding-left: 25px
    }

    .wrapper {
        top: 68%
    }

    .findusheretopdiv {
        padding: 10px 15px 50px
    }

    .newsmain ul li {
        margin-bottom: 40px
    }

    .productlist-main {
        padding-bottom: 20px
    }

    .privacy-main {
        padding-top: 20px
    }

    .ourprojectstop .nav-tabs .nav-link {
        width: 25%
    }

    .ourprojectscontentdiv {
        width: 95%;
        top: 15%;
        position: inherit;
        float: left;
        margin: 20px
    }

    .ourprojectstop .nav-tabs .nav-link:last-child {
        padding: 8px 0
    }

    .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-60%)
    }

    .career-div .form-group {
        margin-bottom: 30px;
        position: relative
    }

    .videoplaydiv {
        position: absolute;
        bottom: 5px;
        left: 5px;
        cursor: pointer;
        z-index: 99999999;
        top: auto
    }

    .videoplaydiv img {
        width: 30px;
        height: 30px;
        filter: brightness(0) invert(1)
    }
}

@media (max-width:376px) {
    .aurealestate-bussinesscontentdivbottom p {
        color: #fff;
        width: 100%
    }

    .accordion-button {
        color: #a4a4a4;
        font-size: 18px;
        padding: 10px 0
    }

    .ourprojectscontentdiv {
        width: 94%;
        position: inherit;
        float: left;
        margin-left: 6%
    }

    .ourprojectstop .nav-tabs .nav-link {
        font-size: 14px
    }

    .ourprojectstop .nav-tabs .nav-link:last-child {
        width: 50%
    }

    .sidebar-contact {
        width: 330px;
        right: -330px
    }
}

@media (max-width:361px) {
    .aurealestate-bussinesscontentdivbottom p {
        color: #fff;
        width: 100%;
        min-height: 80px
    }

    .accordion-button {
        color: #a4a4a4;
        font-size: 18px;
        padding: 10px 0
    }

    .aurealestate-bussinesscontentdivbottom {
        padding: 0 15px;
        bottom: -100px
    }

    .productlist-tab .nav-tabs .nav-link {
        font-size: 14px
    }

    .productlist-tab .nav-tabs .nav-link:last-child {
        width: 55%
    }

    .ourprojectstop .nav-tabs .nav-link {
        width: auto
    }

    .ourprojectstop .nav-tabs .nav-link:last-child {
        width: 50%
    }

    .sidebar-contact {
        width: 300px;
        right: -300px
    }
}

@media (max-width:321px) {
    .inthenewsdivtop a {
        padding: 10px
    }

    .ourprojectstop .nav-tabs .nav-link {
        width: auto
    }

    .ourprojectstop .nav-tabs .nav-link:last-child {
        width: 51%
    }

    .sidebar-contact {
        width: 270px;
        right: -270px
    }
}