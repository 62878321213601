@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.aboutuspage h2,
.aboutuspage h3,
.accordion-button,
.aurealestate-content h1,
.auvalues-main h3,
.awards-leftdiv h2,
.career-div h2,
.consultingpartners-main h2,
.exceeding-expectations-content h3,
.faq-leftdiv h3,
.findusherediv h2,
.findusheretopdiv h2,
.inthenews-main h3,
.leadershipteam-main h2,
.newsmain h2,
.newsmediadivright h3,
.otherdiv h1,
.ourprojectstop h2,
.privacy-main h2,
.productlist-tab .nav-tabs .nav-link,
.service-div h2 {
    font-family: 'Playfair Display', sans-serif !important
}

*,
.sidebar-contact {
    box-sizing: border-box
}

*,
.footermain ul,
.footermainleft ul,
.newsmain ul,
.social-links ul {
    margin: 0;
    padding: 0
}

body {
    font-family: Ubuntureg, sans-serif
}

h1,
h2,
h3 {
    font-size: 50px
}

.header-main .navbar-brand img {
    width: 100px
}

.aurealestate-banner,
.foundermessage-line {
    width: 100%;
    position: relative
}

.aurealestate-banner {
    min-height: 100vh;
    max-height: 100vh;
}

.aurealestate-auphilosopy img,
.aurealestate-banner img,
.auvalues-auphilosopyline,
.auvalues-main img,
.blogdetailmain,
.custom-file-upload input,
.exceeding-expectations img,
.leasingdiv-right img,
.testimonial-left img {
    width: 100%
}

.dropdown .dropdown-menu,
.owl-carousel .owl-nav {
    display: none
}

.plusminus {
    width: 30%;
    text-align: right
}

.plusminus a {
    color: #000;
    line-height: 25px
}

.plus {
    font-size: 30px
}

.minus {
    font-size: 20px;
    margin-left: 20px
}

.header-main .navbar-expand-lg .navbar-nav {
    margin-left: 10%
}

.header-main .navbar-expand-lg .navbar-nav .nav-link {
    padding: 25px 15px
}

.dropdown-item {
    padding: 10px 15px;
    color: var(--bs-nav-link-color);
    overflow: visible;
    cursor: pointer
}

.dropdown-item.active,
.dropdown-item:active,
.sidebar-contact .form-control:focus {
    background-color: transparent
}

.dropdown-menu li ul {
    list-style: none;
    padding: 0;
    position: absolute;
    right: -160px;
    top: 0;
    color: var(--bs-dropdown-link-hover-color);
    background-color: #fff;
    opacity: 0;
    transition: .3s;
    display: none
}

.residencesDropdown:hover ul {
    opacity: 1;
    display: block
}

.aurealestate-content {
    position: absolute;
    bottom: 5%;
    left: 7%;
    width: 35%
}

.aurealestate-content p {
    font-size: 26px;
    color: #fff;
    margin-bottom: 10px
}

.aurealestate-content h1 {
    font-size: 55px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 10px
}

.aurealestate-auphilosopy h2,
.aurealestate-bussiness h2,
.exceeding-expectations h2,
.foundermessage-leftdiv h3 {
    font-size: 50px;
    font-family: 'Playfair Display', sans-serif !important
}

.searchdiv {
    width: 100%;
    float: left;
    position: relative
}

.searchdiv .form-control {
    background-color: #051e3c;
    border-radius: 50px;
    border: none;
    height: 60px;
    padding-left: 25px
}

.searchdiv img {
    width: auto;
    position: absolute;
    right: 25px;
    top: 17px
}

.aurealestate-auphilosopy {
    width: 100%;
    padding: 50px 0 0;
    position: relative;
    background-color: #f7f7f7
}

.aurealestate-auphilosopy img,
.newsmediadivleft img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.aurealestate-auphilosopyline {
    width: 80%;
    height: 85%;
    border: 1px solid #0f817f;
    position: absolute;
    top: 8%
}

.aurealestate-auphilosopy h2 {
    color: #0f817f;
    margin-bottom: 40px;
    position: relative;
    background-color: #f8f8f8;
    width: 28%
}

.aurealestate-auphilosopy p {
    width: 70%;
    padding-left: 100px;
    margin-bottom: 50px
}

.line2 {
    height: 89.5%;
    border-right: 1px solid #0f817f;
    left: 85%;
    top: 86px;
    animation: 6s slidein;
    position: absolute;
    right: 15%
}

.line3 {
    width: 85%;
    position: absolute;
    border-top: 1px solid #0f817f;
    left: 0;
    bottom: 23px;
    animation: 3s slideright
}

.aurealestate-bussiness {
    width: 100%;
    padding: 50px 0;
    background-color: #e9ebea
}

.H3 {
    font-family: 'Ubuntuth', sans-serif !important;
}

.aurealestate-bussiness h2,
.blogdetailmain h3,
.exceeding-expectations h2,
.foundermessage-leftdiv h3 {
    margin-bottom: 40px
}

.aurealestate-bussiness h2::before,
.exceeding-expectations h2::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100px;
    height: 1px;
    background-color: #999;
    margin-top: 30px
}

.aurealestate-bussinessimgmaintop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px
}

.aurealestate-bussinessimgdiv {
    position: relative;
    width: 49.7%;
    height: 100%;
    overflow: hidden
}

.aurealestate-bussinesscontentdiv {
    position: absolute;
    bottom: -80px;
    width: 100%;
    padding: 0 30px;
    -webkit-transition: bottom .8s;
    -moz-transition: bottom .8s;
    -o-transition: bottom .8s;
    -ms-transition: bottom .8s;
    transition: bottom .8s
}

.aurealestate-bussinessimgdiv:hover>.aurealestate-bussinesscontentdiv,
.aurealestate-bussinessimgdivbottom:hover>.aurealestate-bussinesscontentdivbottom,
.leadership-imgdiv:hover>.leadership-contentdiv {
    bottom: 0
}

.aurealestate-bussinessimgdiv img,
.aurealestate-bussinessimgdivbottom img,
.leadership-imgdiv img {
    width: 100%;
    transform: scale(1.1);
    transition: transform .8s
}

.aurealestate-bussinessimgdiv:hover img,
.aurealestate-bussinessimgdivbottom:hover img,
.leadership-imgdiv:hover img {
    transform: scale(1)
}

.aurealestate-bussinesscontentdiv img,
.leadership-contentdiv img {
    width: 120px;
    margin-bottom: 30px
}

.aurealestate-bussinesscontentdiv p {
    color: #fff;
    width: 60%
}

.aurealestate-bussinessimgmainbottom,
.faq-div,
.ourprojectstop {
    width: 100%;
    display: flex;
    justify-content: space-between
}

.aurealestate-bussinessimgdivbottom {
    position: relative;
    width: 32.9%;
    height: 100%;
    overflow: hidden
}

.aurealestate-bussinesscontentdivbottom {
    position: absolute;
    bottom: -100px;
    width: 100%;
    padding: 0 30px;
    -webkit-transition: bottom .8s;
    -moz-transition: bottom .8s;
    -o-transition: bottom .8s;
    -ms-transition: bottom .8s;
    transition: bottom .8s
}

.inthenewsdivtop a,
.newsdiv img,
.newsslider {
    transition: .5s ease-in-out
}

.aurealestate-bussinesscontentdivbottom img {
    width: 130px;
    margin-bottom: 30px
}

.aurealestate-bussinesscontentdivbottom p {
    color: #fff;
    width: 90%;
    min-height: 80px
}

.auvalues-main,
.exceeding-expectations,
.service-div {
    width: 100%;
    padding: 50px 0;
    position: relative
}

.exceeding-expectations h2 {
    padding-left: 120px
}

.exceeding-expectations-content {
    position: absolute;
    bottom: 100px;
    left: 7%
}

.exceeding-expectations-content h3,
.leadership-contentdiv h4 {
    color: #fff
}

.foundermessage-main {
    width: 100%;
    padding: 100px 0 50px;
    background-image: url(../../public/websiteimages/long-bg.png);
    background-size: 100% 100%
}

.foundermessage-line::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 100%;
    border: 2px solid #999;
    top: -30px;
    z-index: -1
}

.foundermessage-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px
}

.foundermessage-leftdiv {
    width: 45%
}

.advisory-productlist-div p a,
.breadcrumb-item.active,
.foundermessage-leftdiv h3,
.plusminus button:focus,
.plusminus button:hover {
    color: #0f817f
}

.foundermessage-leftdiv p {
    margin-bottom: 25px
}

.foundermessage-leftdiv h4 {
    margin-top: 40px;
    font-size: 18px
}

.foundermessage-leftdiv h4 span {
    font-weight: 300;
    font-size: 16px
}

.findusherediv-right,
.foundermessage-rightdiv,
.leasingdiv-right,
.testimonial-left {
    width: 50%;
}

.testimonial-main .testimonial-left {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.testimonial-main .testimonial-right {
    margin-left: 0;
    margin-right: 0;
}

.testimonial-main .testimonial-right .swiper-slide {
    padding-top: 10rem;
    padding-left: 5rem;
    padding-right: 10rem;
}

.testimonial-main .testimonials-swiper-button {
    display: flex;
    margin-left: 5rem;
    gap: 1rem;
}

.testimonial-main .testimonials-swiper-button .testimonials-swiper-button-prev, .testimonial-main .testimonials-swiper-button .testimonials-swiper-button-next {
    border: 1px solid #000;
}

.testimonial-main .testimonials-swiper-button .testimonials-swiper-button-prev svg, .testimonial-main .testimonials-swiper-button .testimonials-swiper-button-next svg {
    width: 5rem;
    height: 2.5rem;
}

.auvalues-auvaluesline {
    width: 95%;
    height: 80%;
    border: 1px solid #000;
    position: absolute;
    top: 10%
}

.auvalues-main h3 {
    margin-bottom: 50px;
    position: relative;
    background-color: #fefbf2;
    width: 20%
}

.accordion-item,
.plusminus button {
    background-color: transparent;
    border: none
}

.accordion-button {
    background-color: transparent;
    color: #a4a4a4;
    font-size: 26px
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: none
}

.accordion-button:focus,
.career-div .form-control:focus {
    box-shadow: none
}

.line4 {
    height: 80%;
    border-right: 1px solid #0f817f;
    left: 85%;
    top: 86px;
    animation: 6s slidein;
    position: absolute;
    right: 6%
}

.line5 {
    width: 94%;
    position: absolute;
    border-top: 1px solid #0f817f;
    left: 0;
    bottom: 88px;
    animation: 3s slideright
}

.advisory-productlist,
.career-div,
.inthenews-main,
.productlist-main {
    width: 100%;
    padding: 50px 0
}

.inthenews-main h3 {
    margin-bottom: 0;
    position: relative
}

.inthenewsdivtop,
.leasingdiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.alllogodiv {
    display: flex;
}

.testimonial-main {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.inthenewsdivtop a {
    border: 2px solid #000;
    color: #000;
    font-size: 16px;
    padding: 10px 20px
}

.inthenewsdivtop a img {
    width: 35px;
    margin-left: 10px
}

.inthenewsdivtop a:hover {
    background-color: #000;
    color: #fff
}

.btn-style:hover img,
.inthenewsdivtop a:hover img {
    filter: brightness(0) invert(1)
}

.newsslider {
    margin-left: 7.7%;
    margin-top: 50px
}

.newsdiv img {
    filter: grayscale(1);
    margin-bottom: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover
}

.advisory-productlist-div p,
.newsdiv p,
.productlisttab-div p {
    min-height: 80px;
    color: #808285;
    font-size: 16px
}

.footer-main-bottom p,
.leasingdiv-left p,
.newsdiv h4 {
    font-size: 16px
}

.advisory-productlist-div h4 a,
.newsdiv h4 a,
.productlisttab-div h4 a {
    color: #808285
}

.advisory-productlist-div h4 span,
.newsdiv h4 span,
.productlisttab-div h4 span {
    float: right;
    font-size: 15px;
    color: #808285;
    opacity: .5
}

.advisory-productlist-div:hover img,
.newsdiv:hover img,
.productlisttab-div:hover img {
    filter: grayscale(0)
}

.accordion-button:not(.collapsed)::after,
.career-div h2,
.faq-leftdiv h3,
.newsdiv:hover h4,
.otherdiv h1 {
    color: #00586f
}

.advisory-productlist-div:hover p,
.breadcrumb-item a,
.footer-main-bottom p span a,
.newsdiv:hover p,
.productlisttab-div:hover p,
.scroll .form-control:focus,
.scroll .form-control:hover {
    color: #000
}

.advisory-productlist-div:hover h4 a,
.newsdiv:hover h4 a,
.productlisttab-div:hover h4 a {
    color: #000;
    text-decoration: underline
}

.footermain {
    width: 100%;
    padding: 50px 0 10px;
    float: left;
    background-color: #f0f2f2
}

.logowithdate img {
    margin-right: 15px
}

.footermain ul li {
    list-style-type: none;
    margin-bottom: 7px
}

.footermain ul li a {
    font-size: 16px;
    color: #000
}

.footermain h5 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #000
}

.footermainleft p {
    font-family: Ubuntulig, sans-serif !important;
    font-size: 16px;
    width: 50%
}

.footerlogo {
    width: 125px;
    margin-bottom: 30px
}

.aboutuspage h2,
.alllogodiv {
    margin-bottom: 40px;
    margin-top: 50px
}

.footermainleft ul li {
    list-style-type: none;
    margin-bottom: 15px
}

.footermainleft ul li a {
    color: #000;
    font-family: Ubuntulig, sans-serif
}

.footermainleft ul li img {
    filter: brightness(1) invert(1);
    margin-right: 10px
}

.footer-main-bottom {
    width: 100%;
    border-top: 1px solid #000;
    padding-top: 15px
}

.footer-main-bottom p span {
    float: right
}

.dropdown-toggle:hover .dropdown-menu,
.testimonial-main .owl-carousel .owl-nav.disabled {
    display: block
}

.alllogodiv {
    width: 100%;
    justify-content: space-between
}

.alllogodiv img {
    width: 120px;
    filter: brightness(1) invert(1)
}

.social-links ul li {
    list-style-type: none;
    float: left;
    margin-right: 10px
}

.findusherediv-left ul li img,
.social-links ul li img {
    width: 35px
}

.testimonial-main {
    width: 100%;
    background-color: #b1b9BC30
}

.video,
.video-main,
.wrapper {
    display: inline-block
}

.testimonial-main .item {
    display: flex;
    align-items: center
}

.testimonial-right::before {
    content: url(../../public/websiteimages/double-cot-icon.png);
    position: absolute;
    top: 11%;
    left: 8%;
}

.testimonial-right img {
    width: 50px !important;
    height: 50px;
    border-radius: 100px;
    float: left;
    margin-right: 15px
}

.testimonial-main .owl-carousel .owl-nav {
    position: absolute;
    top: 80%;
    width: 100%;
    right: 34%;
    text-align: right
}

.awards .owl-carousel .owl-nav button.owl-next,
.awards-main .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.testimonial-main .owl-carousel .owl-nav button.owl-prev {
    border: 1px solid #000;
    padding: 5px 20px !important
}

.video,
.waves {
    background: rgba(255, 255, 255, .3);
    border-radius: 100%
}

.ourprojectsbottom .owl-carousel .owl-nav button.owl-next img,
.ourprojectsbottom .owl-carousel .owl-nav button.owl-prev img,

.wrapper {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.advisory-main,
.awards-leftdiv h2,
.faq-leftdiv h3,
.findusherediv h2,
.service-main,
.video-main {
    position: relative
}

.video {
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    z-index: 999
}

@keyframes waves {
    0% {
        -webkit-transform: scale(.2, .2);
        transform: scale(.2, .2);
        opacity: 0
    }

    50% {
        opacity: .9
    }

    100% {
        -webkit-transform: scale(.9, .9);
        transform: scale(.9, .9);
        opacity: 0
    }
}

.fa-play:before {
    content: "\f04b"
}

a.video:hover {
    color: #051e3c
}

.waves {
    position: absolute;
    width: 150px;
    height: 150px;
    opacity: 0;
    right: -50px;
    bottom: -50px;
    z-index: -1;
    -webkit-animation: 3s ease-in-out infinite waves;
    animation: 3s ease-in-out infinite waves
}

.aboutuspage,
.findushere-main,
.newsmain {
    background-image: url(../../public/websiteimages/About-bg.png)
}

.form-control,
.sidebar-contact .form-control {
    border: none;
    letter-spacing: 1px;
    font-family: Ubuntulig, sans-serif
}

.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}

.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    animation: 1s forwards smoothScroll;
    background-color: #fff;
    width: 100%;
    z-index: 99;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .05)
}

.themeplay,
.themeplayone {
    width: 100% !important;
    object-fit: cover
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px)
    }

    100% {
        transform: translateY(0)
    }
}

.themeplay {
    height: 860px !important
}

.themeplayone {
    height: 600px !important
}

.aurealestate-innerbanner {
    width: 100%;
    position: relative;
    height: 460px;
    max-height: 460px;
    overflow: hidden
}

.aurealestate-innerbanner img {
    width: 100%;
    height: 100%
}

.aboutuspage {
    width: 100%;
    padding: 0 0 150px;
    position: relative;
    background-size: 100%;
    z-index: -2
}

.aboutuspage h2 {
    position: relative;
    background-color: #fdf9f1;
    width: 20%
}

.aboutuspageline {
    width: 80%;
    border: 1px solid #999;
    height: 70%;
    position: absolute;
    top: 20%;
    z-index: -1
}

.aboutuspage p,
.awards-rightdiv {
    width: 70%
}

.visionandmissiondiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px
}

.missiondiv,
.visiondiv {
    width: 49%;
    border: 1px solid #707070;
    padding: 30px;
    background-color: #fff
}

.missiondiv p,
.visiondiv p {
    font-style: italic;
    font-size: 22px;
    width: 100%;
    min-height: 150px
}

.aboutuspage h3 {
    color: #0f817f;
    font-size: 40px;
    margin-bottom: 20px
}

.breadcrum-div {
    width: 100%;
    padding: 20px 0
}

.breadcrumdiv {
    margin-bottom: 60px
}

.newsmain {
    width: 100%;
    padding: 0 0 50px;
    background-repeat: no-repeat
}

.newsmain h2 {
    margin-bottom: 40px;
    color: #00586f;
    position: relative
}

.newsmain h2::before {
    content: "";
    position: absolute;
    left: -117px;
    width: 110px;
    height: 2px;
    background-color: #00586f;
    top: 50%
}

.findusherediv h2::after,
.findusherediv h2::before,
.findusheretopdiv h2::before {
    content: "";
    background-color: #999;
    height: 1px;
    top: 50%
}

.newsmain ul li {
    list-style-type: none;
    border-bottom: 1px solid #818286;
    padding-bottom: 30px;
    margin-bottom: 30px
}

.newsmain ul li:last-child,
.productlist-tab .nav-tabs {
    border-bottom: none
}

.findusherecontact,
.newsmediadiv {
    width: 100%;
    display: flex;
    align-items: center
}

.newsmediadivleft {
    width: 35%;
    aspect-ratio: 1.48/1;
    margin-right: 2%
}

.newsmediadivright {
    width: 100%;
}

.ourprojectstop nav {
    width: 35%
}

.newsmediadivright h3 {
    font-size: 35px;
    margin-bottom: 5px
}

.newsmediadivright .date {
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1rem
}

.newsmediadivright p {
    font-size: 20px;
    color: #808285;
    margin-bottom: 0
}

.logowithdate {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 20px
}

.logowithdate img {
    width: 70px
}

.findushere-main {
    width: 100%;
    padding: 0;
    background-size: 100% 100%
}

.career-div h2,
.findusheretopdiv h2,
.otherdiv h1 {
    margin-bottom: 30px;
    position: relative
}

.findusheretopdiv h2::before {
    width: 100px;
    position: absolute;
    left: -120px
}

.findusherediv {
    width: 100%;
    padding: 50px 0;
    background-color: rgba(15, 129, 127, .1);
    position: relative
}

.findusherediv h2::before {
    width: 8%;
    position: absolute;
    left: -120px
}

.findusherediv h2::after {
    width: 57%;
    position: absolute;
    margin-left: 15px
}

.findusherediv-left {
    width: 30%;
    margin-right: 5%
}

.findusherediv-left h4 {
    margin-bottom: 20px;
    font-size: 40px
}

.faq-rightdiv,
.findusherediv-left p {
    width: 60%
}

.findusherediv-left ul {
    margin: 0;
    padding: 0;
    display: flex
}

.findusherediv-left ul li {
    list-style-type: none;
    margin-right: 10px
}

.findushereline {
    width: 76%;
    border: 1px solid #999;
    height: 70%;
    position: absolute;
    top: 13%;
    border-top: none;
    z-index: -1
}

.mapmap {
    width: 100%;
    height: 450px
}

.breadcrumb {
    margin-bottom: 0
}

.commingsoonpage-main {
    width: 100%;
    padding: 50px 0;
    text-align: center
}

.font-size-changer .font-size-changer-buttons>div {
    border: none !important
}

.privacy-main {
    width: 100%;
    padding: 0 0 50px
}

.privacy-main h2 {
    margin-bottom: 40px;
    color: #0f817f
}

.privacy-main ul {
    margin: 0 0 30px;
    padding: 0 0 0 30px
}

.privacy-main ul li {
    line-height: 35px;
    font-size: 18px;
    font-weight: 400
}

.privacy-main h4 {
    margin-bottom: 20px;
    font-size: 28px
}

.gitintouch {
    width: 170px;
    height: 40px;
    position: absolute;
    left: -108px;
    z-index: 9999;
    right: -52px;
    transform: rotate(0);
    top: 14%;
    cursor: pointer
}

.gitintouch p {
    color: #fff;
    transform: rotate(-90deg);
    background-color: #051e3c;
    padding: 10px;
    text-align: center
}

.sidebar-contact {
    position: fixed;
    top: 25%;
    right: -350px;
    width: 350px;
    height: auto;
    padding: 10px 10px 20px;
    background: #fff;
    transition: .5s;
    z-index: 999;
    box-shadow: 0 0 14px 0 #999
}

.findusheretopdiv .form-control:focus,
.form-control {
    box-shadow: none;
    background-color: transparent
}

.sidebar-contact .form-control {
    border-bottom: 1px solid #999;
    height: 45px;
    margin-bottom: 20px;
    box-shadow: none;
    border-radius: 0;
    font-size: 16px;
    padding-left: 0
}

.sidebar-contact textarea.form-control {
    height: 70px;
    margin-bottom: 30px
}

.awards-leftdiv h2::before,
.consultingpartners-main h2::before,
.leadershipteam-main h2::before {
    content: "";
    position: absolute;
    left: -120px;
    width: 100px;
    height: 1px;
    top: 50%
}

.crossiconimage {
    width: 20px;
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    cursor: pointer
}

.sidebar-contact .btn-style-one {
    padding: 5px 30px
}

.sidebar-contact.active {
    right: 0
}

.sidebar-contact .form-group {
    position: relative;
    margin-bottom: 15px !important
}

.scroll {
    margin-top: 15px
}

.scroll input.form-control::placeholder {
    color: #999
}

.scroll textarea.form-control::placeholder {
    color: #999
}

.btn-style {
    border: 2px solid #000;
    color: #000;
    font-size: 16px;
    padding: 10px 20px;
    transition: .5s ease-in-out;
    background-color: transparent
}

.btn-style:hover {
    background-color: #0f817f;
    color: #fff;
    border: 2px solid #0f817f
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    margin-top: .125em;
    margin-left: .125em
}

@media screen and (min-width:769px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%
    }

    .dropend .dropdown-toggle {
        margin-left: .5em
    }
}

@media (max-width:991.98px) {
    .offcanvas-collapse {
        position: fixed;
        top: 70px;
        left: 100%;
        width: 100%;
        height: 100vh;
        padding-right: 1rem;
        padding-left: 1rem !important;
        overflow-y: auto;
        visibility: hidden;
        background-color: #343a40;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
        z-index: 99
    }

    .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-100%)
    }
}

.advisorycomingsoondiv {
    width: 100%;
    padding: 200px 0;
    text-align: center
}

.form-group {
    margin-bottom: 15px
}

select.form-control {
    appearance: auto
}

input.form-control::placeholder {
    color: #999
}

textarea.form-control::placeholder {
    color: #999
}

select.form-control::placeholder {
    color: #999
}

.form-control {
    border-bottom: 1px solid #999;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 0;
    font-size: 16px;
    padding-left: 0
}

.textareatag {
    height: 100px
}

.youtubevideo,
.ytvideo {
    height: 600px;
    width: 100%
}

.consultingpartners-main {
    width: 100%;
    padding: 100px 0;
    background-image: url(../../public/websiteimages/consulting-partners-bg.png)
}

.consultingpartners-main h2 {
    color: #fff;
    margin-bottom: 50px;
    position: relative
}

.consultingpartners-main h2::before,
.leadershipteam-main h2::before {
    background-color: #fff
}

.consultingpartners-div {
    width: 100%;
    padding-left: 10%
}

.consultingpartners-main img {
    width: 150px !important;
    filter: brightness(1) invert(1)
}

.ytvideo {
    background-size: 100%;
    cursor: pointer
}

.findusheretopdiv {
    padding: 50px 0 100px
}

.findusheretopdiv .form-group {
    position: relative;
    margin-bottom: 20px
}

.findusheretopdiv .btn-style {
    margin-top: 20px
}

.awards-main {
    width: 100%;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.awards-leftdiv {
    width: 25%;
    padding-left: 100px
}

.awards-leftdiv h2::before {
    background-color: #000
}

.awards-leftdiv p {
    margin-bottom: 50px
}

.awardslider-div {
    width: 100%;
    text-align: center;
    border: 1px solid #aaa;
    padding: 40px 20px
}

.awardslider-div img {
    width: 150px !important;
    margin: 0 auto 30px
}

.awardslider-div h4 {
    font-size: 20px
}

.awardslider-div p {
    font-size: 16px;
    color: #666;
    margin-bottom: 0
}

.awardslider-div:hover {
    border: 1px solid #0f817f
}

.awards-main .owl-carousel .owl-nav {
    display: block;
    position: absolute;
    top: 80%;
    width: 20%;
    left: -36%;
    text-align: right
}

.awards-main .owl-carousel .owl-nav button.owl-prev {
    margin-right: 20px
}

.leadershipteam-main {
    width: 100%;
    padding: 100px 0;
    background-image: url(../../public//websiteimages/leadership-team-bg.png)
}

.leadershipteam-main h2 {
    color: #fff;
    position: relative;
    margin-bottom: 20px
}

.career-div h2::before,
.faq-leftdiv h3::before {
    background-color: #00586f;
    content: "";
    left: -120px;
    width: 100px;
    height: 1px
}

.leadershipteam-main p {
    color: rgba(255, 255, 255, .7);
    width: 40%;
    margin-bottom: 50px
}

.leadership-div {
    width: 100%;
    text-align: center;
    border: 1px solid #aaa;
    padding: 0
}

.leadership-imgdiv {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.leadership-contentdiv {
    position: absolute;
    bottom: -75%;
    height: 100%;
    -webkit-transition: bottom .8s;
    -moz-transition: bottom .8s;
    -o-transition: bottom .8s;
    -ms-transition: bottom .8s;
    transition: bottom .8s;
    background: linear-gradient(to bottom, #003a58 0, transparent 100%);
    text-align: center;
    padding: 30px 10px;
    width: 100%
}

.advisory-main,
.career-main,
.service-main {
    width: 100%;
    background-image: url(../../public/websiteimages/About-bg.png);
    background-size: 100% 100%
}

.leadership-contentdiv h5 {
    color: #fff;
    margin-bottom: 30px
}

.leadership-contentdiv p {
    color: #fff;
    width: 100%;
    font-size: 16px
}

.career-div h2::before {
    position: absolute;
    top: 50%
}

.career-div .form-group {
    margin-bottom: 50px;
    position: relative
}

.custom-file-upload {
    width: 100%;
    border-bottom: 1px solid #999;
    height: 50px
}

.advisoryline,
.serviceline {
    width: 80%;
    height: 75%;
    position: absolute;
    top: 10%;
    border: 1px solid #00586f
}

.service-div h2 {
    margin-bottom: 20px;
    position: relative;
    background-color: #fdf8ef;
    width: 18%
}

.service-div p {
    width: 70%;
    margin-bottom: 30px
}

.jointventures img,
.service-div img {
    width: 80%;
    margin-left: 10%
}

.faq-main {
    width: 100%;
    padding: 50px 0;
    background-color: #fdf5e3
}

.faq-leftdiv {
    width: 30%
}

.faq-leftdiv h3::before {
    position: absolute;
    top: 25%
}

.productlist-main .tab-content {
    margin-top: 50px
}

.productlist-tab {
    width: 100%;
    padding: 30px 0;
    background-color: #f5f5f5
}

.productlist-tab .nav-tabs .nav-link {
    border: none;
    font-size: 30px;
    font-weight: 100;
    color: #000;
    opacity: .5;
    text-align: center;
    width: 25%
}

li.nav-item {
    list-style-type: none !important
}

.productlist-tab .nav-tabs .nav-link.active,
.productlist-tab .nav-tabs .productlist-tab .nav-item.show .nav-link {
    opacity: 1;
    color: #0f817f;
    border-bottom: 2px solid #0f817f;
    background-color: transparent
}

.advisory-productlist-div img,
.productlisttab-div img {
    margin-bottom: 20px;
    transition: .5s ease-in-out;
    width: 100%
}

.advisory-productlist-div h4,
.productlisttab-div h4 {
    font-size: 26px
}

.owl-dots {
    display: none !important
}

.leasingdiv-left,
.ourprojectstop .nav-tabs .nav-link:last-child {
    width: 40%
}

.leasingdiv-left h4 {
    font-size: 30px;
    color: #000;
    margin-bottom: 30px
}

.ourprojects {
    width: 100%;
    padding: 50px 0 150px
}

.ourprojectstop h2 {
    color: #0f817f;
    margin-bottom: 40px;
    position: relative
}

.ourprojectstop h2::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -120px;
    width: 100px;
    height: 1px;
    background-color: #0f817f
}

.ourprojectstop .nav-tabs .nav-link {
    width: 30%;
    color: #000;
    font-family: Ubuntureg, sans-serif;
    font-size: 16px
}

.ourprojectstop .nav-tabs {
    border-bottom: none;
    justify-content: flex-end
}

.ourprojectstop .nav-tabs .nav-item.show .nav-link,
.ourprojectstop .nav-tabs .nav-link.active {
    border: 1px solid #000;
    border-radius: inherit
}

.ourprojectscontent {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.ourprojectscontent img {
    width: 80% !important
}

.ourprojectscontentdiv {
    width: 30%;
    background-color: #fff;
    box-shadow: 0 0 10px 0 #999;
    position: absolute;
    top: 20%;
    right: 10px;
    padding: 20px
}

.btn-style img {
    width: 30px !important;
    margin-left: 10px;
    display: inline !important
}

.ourprojectscontentdiv h4 {
    font-size: 40px;
    margin-bottom: 20px
}

.ourprojectscontentdiv h6 {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 20px
}

.ourprojectscontentdiv p {
    font-size: 16px;
    color: #999;
    margin-bottom: 20px
}

.ourprojectscontentdiv a {
    float: left
}

.ourprojectsbottom .owl-carousel .owl-nav {
    display: none;
    bottom: -10%;
    top: auto
}

.ourprojectsbottom .owl-carousel .owl-nav button.owl-next,
.ourprojectsbottom .owl-carousel .owl-nav button.owl-prev {
    border: 1px solid #000;
    padding: 5px 20px !important;
    margin-right: 10px
}

.otherdiv {
    width: 100%;
    padding: 50px 0 0
}

.videoplaydiv {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer
}

.videoplaydiv img {
    width: 40px;
    height: 40px;
    filter: brightness(0) invert(1)
}

.blogdetailmain img {
    width: 100%;
    margin-bottom: 30px
}

.blogdetailmain h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700
}

.blogdetailmain p {
    margin-bottom: 30px;
    color: #808285
}

.blogdetailmain p a {
    text-decoration: underline;
    color: #000
}