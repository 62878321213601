@media only screen and (max-width:1366px) {
    .downloadbrochurefamily {
        width: 20%
    }

    .herofamily {
        height: 100vh;
        padding: 110px 0 100px
    }

    .formdivfamily {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(82, 82, 83, .8);
        padding: 50px 55px;
        width: 480px;
        height: 480px;
        border-radius: 100%
    }

    .aboutmainfamily {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .offers .owl-nav {
        position: absolute;
        top: -31%;
        width: 100%
    }

    .offers .owl-prev {
        right: 7%
    }

    h1,
    h2,
    h3 {
        font-size: 54px
    }

    .constructionupdatesmainfamily .offers .owl-nav {
        position: absolute;
        top: -35%;
        width: 100%
    }

    .constructionupdatesdivfamily .form-control {
        margin-right: 15%
    }
}

@media (max-width:1024px) {

    h1,
    h2,
    h3 {
        font-size: 50px !important
    }

    .herofamily,
    .herofamily img {
        height: 55vh
    }

    .aboutmainfamily {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .offers .owl-nav {
        top: -34% !important
    }

    .offers .owl-prev {
        right: 8% !important
    }

    #AElifestyle img {
        border-radius: 100%;
        width: 100%;
        height: 100%
    }

    .nav-tabs .nav-link {
        font-size: 50px
    }

    .layoutplanmainfamily .nav-tabs {
        width: 90%
    }

    .Aubussinessmainfamily .logofamily {
        width: 180px
    }

    .homeloanlogodivfamily {
        padding: 50px
    }

    .gallerymainfamily .offers .owl-nav {
        top: -28% !important
    }

    .catchmentsmainfamily {
        padding-top: 0;
        background-position: -20% 20%, 95% 10%;
        background-size: 34% 48%, auto auto
    }

    .constructionupdatesmainfamily .offers .owl-nav {
        top: -36%
    }

    .downloadbrochurefamily {
        width: 24%
    }

    .header-wrapper .nav-item {
        margin: 0 5px
    }

    .header-wrapper .navbar-brand img {
        top: -30px;
        width: 65px
    }

    .header-wrapper .nav-item .nav-link {
        font-size: 15px
    }

    .Thegreenvistafamily {
        padding: 50px 0 100px
    }

    .amenitiesmainfamily,
    .layoutplanmainfamily {
        padding: 50px 0
    }

    .constructionupdatesdivfamily .form-control {
        width: 15%;
        margin-right: 16%
    }

    .constructionupdatesmainfamily .owl-dots {
        display: none
    }

    .constructionupdatesmainfamily {
        padding: 50px 0 20px
    }

    .familyhubVideo div {
        aspect-ratio: 16/ 8.5;
    }

    .catchmentsmainfamily ul li {
        padding: 30px 10px
    }

    .catchmentsmainfamily ul li p {
        font-size: 14px
    }

    .Aubussinessmainfamily {
        padding: 50px 0 70px
    }

    .formdivfamily {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(82, 82, 83, .8);
        padding: 70px 55px;
        width: 500px;
        height: 500px;
        border-radius: 100%
    }

    .maplocation {
        padding-left: 0
    }
}

@media (max-width:830px) {
    #ownslider {
        margin-top: 0
    }

    .offers .owl-nav {
        top: -30% !important
    }

    h1,
    h2,
    h3 {
        font-size: 35px !important;
        margin-bottom: 30px !important
    }

    .aboutmainfamily {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #497b68;
        background-image: none
    }

    .aboutmainfamily p {
        padding: 0
    }

    .offers .owl-prev {
        right: 10% !important
    }

    #AElifestyle {
        margin-bottom: 70px
    }

    .Thegreenvistafamily {
        background-image: none;
        padding: 50px 0
    }

    .Thegreenvistafamily h2,
    .Thegreenvistafamily p {
        text-align: center
    }

    .header-wrapper .navbar-brand img {
        position: inherit;
        top: 0;
        width: 65px
    }

    .header-wrapper .fixed-top {
        padding: 10px 0
    }

    .herofamily,
    .herofamily img {
        height: 65vh
    }

    .nav-tabs .nav-link {
        font-size: 40px
    }

    .constructionupdatesdivfamily h2 {
        margin-bottom: 30px
    }

    .constructionupdatesdivfamily .form-control {
        width: 20%;
        margin-right: 20%;
        margin-top: 0
    }

    .constructionupdatesmainfamily .offers .owl-nav {
        top: -38% !important
    }

    .familyhubVideo div {
        aspect-ratio: 16/ 8.5;
    }

    .catchmentsmainfamily ul li {
        width: 23%;
        padding: 20px 5px
    }

    .catchmentsmainfamily ul li p {
        font-size: 12px
    }

    .Aubussinessmainfamily .logofamily {
        width: 135px
    }

    .Aubussinessmainfamily .logofamily img {
        width: 100%
    }

    .contentdetailfamily ul li a {
        color: #000;
        font-size: 13px
    }

    #AElifestyle img {
        border-radius: 100%;
        width: 80%;
        height: 100%
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: column;
        width: 100%;
        float: right;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 0 solid rgba(255, 255, 255, .5)
    }

    .navbar-collapse {
        background-color: #497b68;
        padding-bottom: 10px
    }

    .downloadbrochurefamily {
        margin-left: 5px
    }

    .navbar-toggler img {
        width: 35px
    }

    .formdivfamily {
        padding: 40px 55px
    }

    .Thegreenvistafamily #ownslider {
        margin-top: 0;
        margin-bottom: 100px
    }

    .homeloadmainfamily {
        padding: 0 0 50px
    }

    .gallerymainfamily {
        width: 100%;
        padding: 50px 0
    }

    .catchmentsmainfamily {
        background-position: -20% 20%, 95% 10%;
        background-size: 34% 48%, auto auto;
        padding-bottom: 50px
    }

    .AmenitiesSection .Amenities-button {
        bottom: 0;
        gap: 0.5rem;
    }

    .constructionupdatesmainfamily .constructionupdatesdivfamily .construction-button {
        gap: 0.5rem;
    }
}

@media (max-width:540px) {

    .AmenitiesSection .Amenities-button {
        bottom: 0;
        gap: 0.5rem;
    }

    .herofamily,
    .herofamily img {
        height: auto
    }

    .navbar-toggler img {
        width: 35px
    }

    .formdivfamily {
        border-radius: 0;
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 170px;
        padding: 15px
    }

    .offers .owl-prev {
        right: 15% !important
    }

    [data-aos-delay] {
        transition-delay: 0 !important
    }

    .herofamily {
        padding: 160px 0 15px;
        min-height: auto
    }

    h2,
    h3 {
        font-size: 34px !important
    }

    .layoutplanmainfamily ul {
        padding: 0 0 0 15px;
        margin-left: 15px
    }

    .layoutplanmainfamily ul li {
        width: 100%
    }

    .header-wrapper .navbar-brand img {
        position: inherit;
        top: 0;
        width: 50px
    }

    .nav-tabs .nav-link {
        font-size: 20px
    }

    .catchmentsmainfamily {
        background-image: none;
        padding-bottom: 50px
    }

    .amenitiesmainfamily h1,
    .catchmentsmainfamily h3,
    .footer-logo,
    .gallerymainfamily h2 {
        margin-bottom: 20px
    }

    .catchmentsmainleftfamily {
        text-align: center;
        margin-bottom: 30px
    }

    .catchmentsmainleftfamily img {
        width: 50%
    }

    .catchmentsmainfamily ul li {
        width: 48%;
        padding: 20px
    }

    .catchmentsmainfamily ul li p {
        font-size: 14px
    }

    .Aubussinessmainfamily .logofamily {
        width: 46%;
        margin-bottom: 20px
    }

    .homeloanlogodivfamily h3 {
        padding-top: 0;
        text-align: center
    }

    .homeloadmainfamily {
        padding: 0 0 50px
    }

    .constructionupdatesdivfamily {
        display: inherit
    }

    .constructionupdatesdivfamily .form-control {
        width: 40%;
        margin-bottom: 20px
    }

    .constructionupdatesmainfamily .offers .owl-nav,
    .offers .owl-nav {
        top: -20% !important
    }

    .familyhubVideo div {
        aspect-ratio: 16/ 8.5;
    }

    .gallerymainfamily .offers .owl-nav {
        top: -24% !important
    }

    .layoutplanmainfamily img {
        width: 100%;
        margin-bottom: 20px
    }

    .homeloanlogodivfamily {
        padding: 20px
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: column;
        width: 100%;
        float: left;
        align-items: flex-start;
        justify-content: flex-end;
        border-bottom: 0 solid rgba(255, 255, 255, .5)
    }

    .downloadbrochurefamily {
        margin-left: 5px
    }

    .offers .owl-nav {
        top: -17%
    }

    .Thegreenvistafamily,
    .amenitiesmainfamily {
        padding: 50px 0 20px
    }

    .gallerymainfamily {
        padding-bottom: 50px
    }

    .fixed-top,
    footer {
        padding-bottom: 20px
    }

    .navbar-collapse {
        background-color: #497b68;
        padding-bottom: 10px;
        padding-left: 15px
    }

    .footermainfamily h6,
    footer h6 {
        margin-top: 15px
    }

    .footer-logofamily {
        margin-bottom: 30px
    }

    .header-wrapper .fixed-top {
        padding: 10px 0
    }
}

@media (max-width:376px) {
    .formdivfamily {
        margin-top: 140px
    }

    .constructionupdatesmainfamily .offers .owl-nav {
        top: -24% !important
    }

    .offers .owl-nav {
        top: -22% !important
    }

    .gallerymainfamily .offers .owl-nav {
        top: -26% !important
    }
}

@media (max-width:361px) {

    h2,
    h3 {
        font-size: 24px !important
    }

    .constructionupdatesdivfamily .form-control {
        width: 50%;
        margin-bottom: 20px
    }

    .offers .owl-prev {
        right: 25%
    }

    .nav-tabs .nav-link {
        font-size: 16px
    }

    .constructionupdatesmainfamily .offers .owl-nav {
        top: -25%
    }

    .catchmentsmainfamily ul li p {
        font-size: 13px
    }

    .Aubussinessmainfamily .logofamily {
        width: 48%;
        margin-bottom: 20px
    }

    .gallerymainfamily h2 {
        margin-bottom: 30px
    }

    .gallerymainfamily {
        padding-bottom: 50px
    }

    .formdivfamily {
        margin-top: 130px
    }
}

@media (max-width:321px) {
    .formdiv {
        margin-top: 35px
    }

    .gallerymainfamily .offers .owl-nav {
        top: -30%
    }

    .offers .owl-nav {
        top: -21%
    }

    .offers .owl-prev {
        right: 20% !important
    }

    .formdivfamily {
        margin-top: 100px
    }
}